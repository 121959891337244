.footer {
  background-color: #000;
  color: #fff;
  padding: 40px 20px;
}

.footer-content {
  display: flex;
  justify-content:flex-start;
}

.footer-section {
  flex: 1;
  margin: 20px;
}

.footer-section h3 {
  font-size: 18px;
  margin-bottom: 20px;
}

.footer-section ul {
  list-style: none;
  padding: 0;

  
  }
.footer-section a{
  text-decoration: none;
  color: #fff;
}

.footer-section li {
  margin-bottom: 20px;
  color: blanchedalmond;
  background-color: none;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
}

/* Responsive Fix for Smaller Screens */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Stack sections on small screens */
    text-align: center; /* Center align for better appearance */
  }

  .footer-section {
    margin: 10px 0;
  }
}