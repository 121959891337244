/* Overall Layout */
.comprehensive-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: linear-gradient(to bottom, #1c1c1c, #f0f5ff);
    color: #000;
    padding: 30px;
    font-family: Arial, sans-serif;
    border-radius: 8px;
    gap: 20px;
  }
  
  .left-section {
    flex: 1;
    padding-right: 20px;
  }
  
  .right-section {
    flex: 1;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Header Section */
  .header-section h1 {
    color: #fff;
    background-color: #1c1c1c;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .description {
    margin: 20px 0;
    font-size: 18px;
  }
  
  /* Benefits Section */
  .benefits-section h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .benefits-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 15px;
    padding: 0;
  }
  
  .benefits-list li {
    background-color: #d1e4ff;
    color: #0056ff;
    border-radius: 20px;
    padding: 10px 15px;
    font-weight: bold;
  }
  
  /* Steps Section */
  .next-steps h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .steps-list {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  
  .step {
    display: flex;
    align-items: center;
  }
  
  .step-number {
    background-color: #0056ff;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-right: 10px;
  }
  
  /* Right Section Form */
  .consultation-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    gap: 10px;
  }
  
  input,
  textarea,
  select {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea {
    resize: none;
  }
  
  .submit-button {
    background-color: #0056ff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #0041cc;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .comprehensive-container {
      flex-direction: column;
    }
  
    .left-section {
      padding-right: 0;
    }
  }
  