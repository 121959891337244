/* Container Styling */
.services-container {
    text-align: center;
    padding: 40px;
    font-family: Arial, sans-serif;
  }
  
  /* Header Section */
  .services-header h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .services-subtitle {
    font-size: 18px;
    color: #555;
    margin-bottom: 30px;
  }
  
  /* Image Section */
  .services-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 30px;
  }
  
  /* Description Section */
  .services-description p {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  
  /* Comprehensive IT Section */
  .comprehensive-it {
    display: flex;
    justify-content: center;
    padding: 40px 20px;
    background-color: #f9f9f9;
  }
  
  .comprehensive-content {
    max-width: 1000px;
    text-align: left;
  }
  
  .comprehensive-content h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #000; /* Adjust for your theme */
  }
  
  .comprehensive-text p {
    font-size: 16px;
    color: #555;
    line-height: 1.8;
    margin-bottom: 15px;
  }
  