/* Define fade-in animation */
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Apply the animation */
  .fade-in {
    animation: fadeIn 1.5s ease-in-out;
    opacity: 1;
  }
  