/* Main container styles */
.container-qt {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 90%;
  margin: 0 auto;
  background-color: rgb(180, 198, 227);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Section titles */
.section-title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

/* Output box and input field styles */
.output-box,
.input-box {
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #555;
  width: 100%; /* Set to full width */
  max-width: 90%; /* Keep it consistent */
  display: block; /* Prevent shrinking */
  margin-bottom: 15px; /* Ensure equal spacing */
}

/* Button styles */
.generate-button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  max-width: 200px; /* Keep button sizes consistent */
}

.generate-button:hover {
  background-color: #0056b3;
}
